import { ClaimKind } from "../../models/claimKind";
import { DefendantDefenseKind } from "../../models/defendantDefenseKind";
import { ProductKind } from "../../models/productKind";

/**
 * Enum for the documents that have an associated user tracking event
 * @readonly
 * @enum {string}
 */
const documentKinds = {
    PURCHASED_DEMAND_LETTER: "PURCHASED_DEMAND_LETTER",
    DEMAND_LETTER: "DEMAND_LETTER",
    SC100: "SC100",
    SC100A: "SC100A",
    SC103: "SC103",
    FILE_AND_SERVE_INSTRUCTIONS: "FILE_AND_SERVE_INSTRUCTIONS",
    TRIAL_PRESENTATION_PLAINTIFF: "TRIAL_PRESENTATION_PLAINTIFF",
    TRIAL_PRESENTATION_DEFENDANT: "TRIAL_PRESENTATION_DEFENDANT",
    TRIAL_PRESENTATION_JUDGE: "TRIAL_PRESENTATION_JUDGE",
    COURT_PREP_INFO_PACKET: "COURT_PREP_INFO_PACKET",
    PURCHASED_SETTLEMENT_LETTER: "PURCHASED_SETTLEMENT_LETTER",
    SETTLEMENT_LETTER: "SETTLEMENT_LETTER",
};

const authEvents = {
    SIGNUP: "Signup",
    LOGIN: "Login",
    GOOGLE_SIGNUP: "Google Signup",
    GOOGLE_LOGIN: "Google Login",
    LOGOUT: "Logout",
};

const downloadEvents = {
    [documentKinds.PURCHASED_DEMAND_LETTER]: "Purchased DL download",
    [documentKinds.DEMAND_LETTER]: "DL download",
    [documentKinds.SC100]: "SC-100 downloaded",
    [documentKinds.SC100A]: "SC-100A downloaded",
    [documentKinds.SC103]: "SC-103 downloaded",
    [documentKinds.FILE_AND_SERVE_INSTRUCTIONS]: "File & Serve instructions download",
    [documentKinds.TRIAL_PRESENTATION_PLAINTIFF]: "P Trial Presentation download",
    [documentKinds.TRIAL_PRESENTATION_DEFENDANT]: "D Trial Presentation download",
    [documentKinds.TRIAL_PRESENTATION_JUDGE]: "J Trial Presentation download",
    [documentKinds.COURT_PREP_INFO_PACKET]: "Court Prep Info Packet download",
    [documentKinds.PURCHASED_SETTLEMENT_LETTER]: "Purchased SL download",
    [documentKinds.SETTLEMENT_LETTER]: "SL download",
};

const emailEvents = {
    [documentKinds.PURCHASED_DEMAND_LETTER]: "Purchased DL email",
    [documentKinds.DEMAND_LETTER]: "DL email",
    [documentKinds.SC100]: "SC-100 email",
    [documentKinds.SC100A]: "SC-100A email",
    [documentKinds.SC103]: "SC-103 email",
    [documentKinds.FILE_AND_SERVE_INSTRUCTIONS]: "File & Serve instructions email",
    [documentKinds.TRIAL_PRESENTATION_PLAINTIFF]: "P Trial Presentation email",
    [documentKinds.TRIAL_PRESENTATION_DEFENDANT]: "D Trial Presentation email",
    [documentKinds.TRIAL_PRESENTATION_JUDGE]: "J Trial Presentation email",
    [documentKinds.COURT_PREP_INFO_PACKET]: "Court Prep Info Packet email",
    [documentKinds.PURCHASED_SETTLEMENT_LETTER]: "Purchased SL email",
    [documentKinds.SETTLEMENT_LETTER]: "SL email",
};

const purchaseEvents = {
    [ProductKind.MailDemandLetterCertifiedMailStandardShipping]: "Demand Letter purchase",
    [ProductKind.DemandLetterCopy]: "Demand Letter copy purchase",
    [ProductKind.PlaintiffTrialPresentation]: "Trial Presentation purchase",
    [ProductKind.MailSettlementLetterCertifiedMailStandardShipping]: "Settlement Letter purchase",
    [ProductKind.SettlementLetterCopy]: "Settlement Letter copy purchase",
    [ProductKind.AttorneyDemandLetterReview]: "Attorney Demand Letter purchase",
};

const defendantDefenseKindEvents = {
    [DefendantDefenseKind.DoesNotDispute]: "Does not dispute",
    [DefendantDefenseKind.DisputesDamages]: "Disputes damages",
    [DefendantDefenseKind.DisputesClaim]: "Disputes claim",
};

const claimKindEvents = {
    [ClaimKind.Unknown]: "COA_Unknown",
    [ClaimKind.Defamation]: "COA_Defamation",
    [ClaimKind.BreachOfContractLoan]: "COA_BreachOfContract",
    [ClaimKind.Nuisance]: "COA_Nuisance",
    [ClaimKind.TrespassToChattels]: "COA_TrespassToChattels",
    [ClaimKind.Conversion]: "COA_Conversion",
    [ClaimKind.Other]: "COA_Other",
    [ClaimKind.Negligence]: "COA_Negligence",
    [ClaimKind.StrictLiability]: "COA_StrictLiability",
    [ClaimKind.IntentionalTort]: "COA_IntentionalTort",
};

const errorEvents = {
    AppCrash: "app_crash",
};

export {
    authEvents,
    downloadEvents,
    emailEvents,
    documentKinds,
    purchaseEvents,
    defendantDefenseKindEvents,
    claimKindEvents,
    errorEvents,
};
